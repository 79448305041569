<template>
  <div class="container my-2" v-if="getRequest">
    <div class="row">
      <div class="col-12 mt-5 container">
        <div class="bd-example w-100">
          <div
            id="carousel-home"
            class="carousel slide row d-flex justify-content-center"
            data-ride="carousel"
            style="height: 20rem"
          >
            <div class="carousel-inner w-100 h-100">
              <div
                class="carousel-item pt-0 w-100 h-100"
                v-for="(slide, i) in getRequest.images"
                :class="{ active: isOne(i) }"
              >
                <img
                  rel="preload"
                  class="d-block mx-auto w-100 h-100"
                  :src="'https://images.scrapiz.com/' + slide"
                  alt="part image"
                />
              </div>
            </div>

            <div class="row">
              <ol class="carousel-indicators">
                <li
                  v-for="(slide, i) in getRequest.images"
                  data-target="#carousel-home"
                  :data-slide-to="i"
                  :class="{ active: isOne(i) }"
                ></li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-lg-6 col-sm-12">
        <div class="row justify-content-between">
          <div
            class="input-group input-group-lg mb-3 col-12"
            style="direction: ltr"
          >
            <span class="form-control text-center">
              {{ getRequest.manufacturer }}
            </span>
            <span class="input-group-text bw-span">الشركة المصنعة</span>
          </div>
          <div
            class="input-group input-group-lg mb-3 col-12"
            style="direction: ltr"
          >
            <span class="form-control text-center">
              {{ getRequest.model }}
            </span>
            <span class="input-group-text bw-span">السيارة</span>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12">
        <div class="row justify-content-between">
          <div
            class="input-group input-group-lg mb-3 col-12"
            style="direction: ltr"
          >
            <span class="form-control text-center">
              {{ getRequest.year }}
            </span>
            <span class="input-group-text bw-span">سنة الصنع</span>
          </div>
          <div
            class="input-group input-group-lg mb-3 col-12"
            style="direction: ltr"
          >
            <span class="form-control text-center">
              {{ getRequest.name }}
            </span>
            <span class="input-group-text bw-span">اسم القطعة</span>
          </div>
        </div>
        <div></div>
      </div>
    </div>

    <div class="col-12 my-4">
      <div
        class="input-group input-group-lg"
        style="direction: ltr; height: 8rem"
      >
        <span
          class="form-control text-center"
          style="direction: ltr; height: 100%"
        >
          {{ getRequest.description }}
        </span>

        <span class="input-group-text">معلومات إضافية</span>
      </div>
    </div>

    <vue-good-table
      :columns="columns"
      :rows="getRequest.offers"
      v-if="getRequest.offers"
      :rtl="true"
      :lineNumbers="true"
      styleClass="vgt-table striped bordered"
      :pagination-options="{
        enabled: true,
        nextLabel: 'التالي',
        prevLabel: 'السابق',
        rowsPerPageLabel: 'عدد الصفوف',
        ofLabel: 'من',
        pageLabel: 'صفحة',
      }"
      class="px-1"
      compactMode
    >
      <div slot="emptystate">
        لايوجد هناك عروض حتى الان سوف يرسل لك البائعين العروض قريباً
      </div>
      <template slot="table-row" slot-scope="props" >
        <span v-if="props.column.field == 'actions'">
          <button
            class="btn btn-sm mx-2 bwj-btn"
            @click="OfferDetails($event, props.row)"
          >
            التفاصيل
          </button>
          <button
            class="btn btn-success btn-sm"
            @click="submit($event, props.row)"
          >
            قبول
          </button>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import { GET_FULL_REQUST } from "../graphql/queries";

export default {
  data() {
    return {
      userInfo: {
        Fname: "",
        Lname: "",
        Email: "",
      },
      showModal: false,
      selectedOffer: null,
      stepNo: 1,
      columns: [
        {
          label: "موقع التشليح",
          field: "address.city",
        },

        {
          label: "قيمة القطعة",
          field: "offerAmount",
          formatFn: this.formatFn,
          type: "number",
        },
        {
          label: "قيمة الشحن",
          field: "shippingAmount",
          formatFn: this.formatFn,
          type: "number",
        },
        {
          label: "الإجمالي ",
          field: this.total,
          formatFn: this.formatFn,
          type: "number",
        },
        {
          label: " ",
          field: "actions",
          sortable: false,
       
        },
      ],
      routeParam: this.$route.params.requestID,
      getRequest: null,
      selectedOffer: null,
    };
  },
  apollo: {
    getRequest: {
      query: GET_FULL_REQUST,
      variables() {
        return {
          RequestID: this.$route.params.requestID,
        };
      },

      result(result) {
        this.$store.commit("glopal/isLoading", false);
      },
    },
  },
  components: {
    VueGoodTable,
  },
  methods: {
    OfferDetails(e, payload) {
      this.$router.push(
        "/offers/offerDetails/" + this.getRequest._id + "/" + payload._id
      );
    },
    total(rowObj) {
      return rowObj.shippingAmount + rowObj.offerAmount;
    },

    formatFn(value) {
      return value + "ريال";
    },

    submit(e, payload) {
      console.log(this.getRequest);

      this.$router.push({
        name: "UserCompleteOrder",
        params: {
          requestID: this.getRequest._id,
          offerID: payload._id,
        },
      });
    },

    isOne(index) {
      if (index == 0) {
        return true;
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
</style>
